import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const IndexPage = () => (
  <Layout fullMenu>
    <SEO />
    <section id="banner">
      <div className="inner align-right">
        {/*<div className="logo">
          <span className="icon fa-horse"></span>
        </div>*/}
        <h2 className="onWhite">Consultation Form</h2>
        <p className="goAway">Hey, stop that already!</p>
        <p className="onWhite" />
      </div>
    </section>

    <section id="wrapper">
      <section id="one" className="wrapper alt last pricingPage">
        <div className="inner align-center">
          <h3 className="major title bafafuColor">
            Our Adventure Together Begins Here
          </h3>
          <p className="align-left">
            Interested in having us build a website for you? Fill out the below
            form, and we'll get in touch! Please note it may take us up to four
            weeks after commission to complete your site, and we are usually
            able to check these form submissions twice a day.
          </p>
        </div>
        <div className="inner">
          <section className="features">
            <article className="span12 pricingPage">
              <form
                action={`https://formspree.io/xwkrlvvp`}
                method="POST"
                name="contact"
                data-netlify="true"
              >
                <div className="row gtr-uniform align-left">
                  <div className="fields col-6">
                    <div className="field">
                      <label htmlFor="name-first">First Name *</label>
                      <input
                        type="text"
                        name="name-first"
                        id="name-first"
                        required
                      />
                    </div>

                    <div className="field">
                      <label htmlFor="email">E-mail *</label>
                      <input type="email" name="email" id="email" required />
                    </div>
                  </div>
                  <div className="fields col-6">
                    <div className="field">
                      <label htmlFor="name-last">Last Name *</label>
                      <input
                        type="text"
                        name="name-last"
                        id="name-last"
                        required
                      />
                    </div>

                    <div className="field">
                      <label htmlFor="phone">Phone *</label>
                      <input type="tel" name="phone" id="phone" required />
                    </div>
                  </div>
                  <div className="fields col-12">
                    <div className="field">
                      <label htmlFor="customer-about">
                        Are you a business sir? What kind of products do you
                        trade in? Tell us all about it! *
                      </label>

                      <textarea
                        name="customer-about"
                        id="customer-about"
                        rows="3"
                        required
                      ></textarea>
                    </div>
                  </div>

                  <div className="fields col-6">
                    <div className="field">
                      <label htmlFor="customer-date">
                        When do you need your website by? *
                      </label>
                      <input
                        type="date"
                        name="customer-date"
                        id="customer-date"
                        required
                      />
                    </div>
                    <div className="field">
                      <label htmlFor="customer-commit">
                        Are you able to budget for our services? *
                      </label>
                      <input
                        type="radio"
                        name="customer-commit"
                        id="customer-commit-yes"
                        value="true"
                        required
                      />
                      <label htmlFor="customer-commit-yes">Yes</label>
                      <input
                        type="radio"
                        name="customer-commit"
                        id="customer-commit-no"
                        value="false"
                      />
                      <label htmlFor="customer-commit-no">No</label>
                    </div>
                    <div className="field">
                      <label htmlFor="customer-domain">
                        Do you have a domain already? *
                      </label>
                      <input
                        type="radio"
                        name="customer-domain"
                        id="customer-domain-yes"
                        value="true"
                        required
                      />
                      <label htmlFor="customer-domain-yes">Yes</label>
                      <input
                        type="radio"
                        name="customer-domain"
                        id="customer-domain-no"
                        value="false"
                      />
                      <label htmlFor="customer-domain-no">No</label>
                    </div>
                  </div>
                  <div className="fields col-6">
                    <div className="field">
                      <label htmlFor="customer-services">
                        Choose your starting package. *
                      </label>
                      <select
                        name="customer-services"
                        id="customer-services"
                        required
                      >
                        <option value="">- select -</option>
                        <option value="unicorn">
                          Full Unicorn Treatment - $7,800
                        </option>
                        <option value="fancyTophat">
                          Fancy Top Hat - $6,750
                        </option>
                        <option value="basicTophat">
                          Basic Top Hat - $5,200
                        </option>
                      </select>
                    </div>
                    <div className="field">
                      <label htmlFor="customer-assets">
                        Do you have assets already? *
                      </label>
                      <input
                        type="radio"
                        name="customer-assets"
                        id="customer-assets-yes"
                        value="true"
                        required
                      />
                      <label htmlFor="customer-assets-yes">Yes</label>
                      <input
                        type="radio"
                        name="customer-assets"
                        id="customer-assets-no"
                        value="false"
                      />
                      <label htmlFor="customer-assets-no">No</label>
                    </div>

                    <div className="field">
                      <label htmlFor="customer-url">
                        If you have a domain, what is it?
                      </label>
                      <input
                        type="text"
                        name="customer-url"
                        id="customer-url"
                        default="www."
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="field">
                      <label htmlFor="customer-interrogation">
                        Anything else you want us to know?
                      </label>
                      <input
                        type="text"
                        name="customer-interrogation"
                        id="customer-interrogation"
                      />
                    </div>

                    <div className="field">
                      <label htmlFor="customer-source">
                        How did you find us?
                      </label>
                      <input
                        type="text"
                        name="customer-source"
                        id="customer-source"
                      />
                    </div>
                  </div>
                </div>
                <button type="submit">Submit</button>
              </form>
            </article>
          </section>
        </div>
      </section>
    </section>
  </Layout>
);

export default IndexPage;
